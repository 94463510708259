.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial;
  font-size: 12px;
  color: #222;
  background-color: #D1D1D1;
}
.window {
  box-shadow: 15px 15px 5px rgb(0 0 0 / 23%);
  border: 2px solid #000;
  background: silver;
  z-index: 999999;
 width: 80vw ;


}
.title-bar {
  background-color: #7a88be;
  background-image: none;
  padding: 5px;
}
.title-bar {
  border-bottom: 2px solid #000;
}

.title-bar {
  background: linear-gradient(
90deg,navy,#1084d0);
  padding: 3px 2px 3px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-bar-text {
  font-family: 'Alef', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  padding-left: 10px;
  cursor: default;
}
.title-bar-text {
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  margin-right: 24px;
}
.title-bar-controls {
  display: flex;
}
.window-body {
  border: 1px solid #000;
}
.window-body {
  margin: 15px;
  background-color: #fff;
  padding: 17px 32px 37px 32px;
}
.presell-items {
  display: flex;
}
.presell-title {
  padding: 20px;
  flex: 0 0 55%;
  
}
.presell-title p {
  font-family: 'Agency FB', sans-serif;
  font-size: 5.5rem;
  font-weight: bold;
  color: #ff0000;
}
.presell-title a {
  display: inline-block;
  padding: 20px;
  background-color: #e60036;
  border: 2px solid #000;
  margin: 40px 50px 0 50px;
  font-family: Arial, sans-serif;
  font-weight: bolder;
  font-size: 3rem;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
}
.presell-product {
    flex: 0 0 40%;
}
.img-border {
  background-image: linear-gradient(to right, #cf2bff, #23baec);
  padding: 8px;
}
/* img {
  background-color: white !important;

} */
.img-content {
  background-color: #fff;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10000;

}
.product-text {
  font-family: 'Agency FB', sans-serif;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  background-image: -webkit-linear-gradient(
180deg, #c838f0, #0aceeb);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text{
  font-size: 3vw !important;
  color: #000 !important;
}



.module-border-wrap {
  max-width: 250px;
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, red, purple);
  padding: 3px;
}

.module {
  background: #222;
  color: white;
  padding: 2rem;
}
.content {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.btn-close {
  color: #fff;
  background-color: #e60036;
  border: 1px solid #000;
  box-shadow: none;
  border-radius: 2px;
}
.title-bar-controls button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}
.title-bar-controls button {
  padding: 0;

  min-width: 16px;
  min-height: 14px;
}
.header-menu button {
  padding: 5px 14px;
  margin: 0 10px;
  box-shadow: inset -2px -2px #0a0a0a, inset 1px 1px #fff, inset -3px -3px grey, inset 2px 2px #dfdfdf;
  font-family: 'Alef', sans-serif;
  font-weight: bold;
  color: #4e5981;
  background-color: silver;
}
.header-menu{
  position:relative;
  top:0;
  right:0;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width:800px) {
  .window {
    box-shadow: 15px 15px 5px rgb(0 0 0 / 23%);
    border: 2px solid #000;
    background: silver;
    z-index: 999999;
   width: fit-content ;
  
  
  }
  .presell-title a {
    display: inline-block;
    padding: 20px;
    background-color: #e60036;
    border: 2px solid #000;
    margin: 40px 50px 0 80px !important;
    font-family: Arial, sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
  }
  .content {
    position: relative;

  }
  .text{
    font-size: 9vw !important;
    color: #000 !important;
  }
  .header-menu button {
    font-size: 4vw !important;
  }

  .btn__align{
    position:relative;
    
    top:0;
    right:-8rem !important;
    display: flex;
    justify-content: flex-end;
  }

}


@media screen and (max-width:400px) {


  .btn__align{
    position:relative;
    
    top:0;
    right:-11rem !important;
    display: flex;
    justify-content: flex-end;
  }

}

@media screen and (max-width:1400px) {



  .presell-title a {
    display: inline-block;
    padding: 20px;
    background-color: #e60036;
    border: 2px solid #000;
    margin: 40px 50px 0 50px;
    font-family: Arial, sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
  }

}
